import {
  API_ENDPOINT_EDIT_PROFILE_DATA,
  API_ENDPOINT_UPLOAD_IMAGE,
  AUTH_TOKENS_KEY,
  CONTENT_TYPES,
} from "./constants";

import { jwtDecode } from "jwt-decode";
/**
 * Get the content type
 * @param {
 *      requestUrl : {Type : string}
 *      contentType : {Type : string}  
 * }  
 * @returns 
 */
export const getContentType = (
  requestUrl,
  contentType = CONTENT_TYPES?.APPLICATION_JSON
) => {
  if (!requestUrl) return;

  const formDataUrls = [
    API_ENDPOINT_UPLOAD_IMAGE,
    API_ENDPOINT_EDIT_PROFILE_DATA,
  ];
  const separator = "/api/";
  const separatorQueryString = "?";

  let searchString = stringToSearch(requestUrl, separator, false);

  if (searchString.includes(separatorQueryString)) {
    searchString = stringToSearch(searchString, separatorQueryString, true);
  }

  const isExists = formDataUrls.includes(searchString);
  if (isExists) {
    contentType = CONTENT_TYPES?.MULTIPART_FORM_DATA;
  }
  return contentType;
};

const stringToSearch = (stringToSplit, separator, isQueryString = false) => {
  const arrayOfStrings = stringToSplit.split(separator);
  if (!isQueryString) {
    const lastString = arrayOfStrings[arrayOfStrings.length - 1];
    return `${separator}${lastString}`;
  }
  return arrayOfStrings[0];
};

/**
 * Capitalize the first character of the given string after removing { , } from this
 * @param {string : Type :string} 
 * @returns capitalString
 */
export const capitalizeString = (string) => {
  let capitalString = string?.replace(/[{}']+/g, '')
  capitalString = capitalString[0].toUpperCase() + capitalString.slice(1)
  return capitalString
}


export const getParentsValues = (data) => {
  let parents = [];
  let values = [];
  let labels = [];
  let parValSum = [];

  for (let ele of data) {
    let sum = 0;
    for (let obj of ele?.Dishes) {
      sum += obj?.Count;
    }
    parValSum.push(sum);
  }

  for (let i = 0; i < data.length; i++) {
    let ele = data[i];
    for (let obj of ele?.Dishes) {
      parents.push(`${capitalizeString(ele?.Cuisine)} .`);
      values.push(obj?.Count * (ele.CuisinesCount / parValSum[i]));
      // Truncate label if it exceeds 3 words
      labels.push(`${capitalizeString(obj?.Dish)} ${ele.Cuisine}`);
    }
  }

  for (let i = data?.length - 1; i >= 0; i--) {
    parents.unshift("");
    values.unshift(data[i].CuisinesCount);
    labels.unshift(`${capitalizeString(data[i]?.Cuisine)} .`);
  }

  return {
    parents: parents,
    values: values,
    labels: labels
  };
};

/**
 * Search the user in the given data 
 * @param {
 *        searchedStr : Type:{string}
*         userData : Type:{Object}
 * } 
 * @returns 
 */
export const searchUser = (searchedStr, usersData) => {
  let newUserData = usersData?.filter(ele => {
    return (
      ele?.name.toLowerCase().includes(searchedStr.toLowerCase()) ||
      ele?.email.toLowerCase().includes(searchedStr.toLowerCase())
    )
  })
  return newUserData
}

/**
 * Round off the num
 * @param {num : Type:int}  
 * @returns 
 */
export const customRound = (num) => {
  if (num > 4.5) {
    return 5
  } else if (num > 3.5) {
    return 4
  } else if (num > 2.5) {
    return 3
  } else if (num > 1.5) {
    return 2
  } else if (num > 0.5) {
    return 1
  }
};

/**
 * Capitalize the first character of the given string after removing { , } from this
 * @param {str : Type :string} 
 * @returns words
 */
export const manipulateString = (str) => {
  str = str?.replace(/[{}']+/g, '')
  let words = str?.split(',');

  // Capitalize the first letter of each word
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.trim()
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1);
  }

  // Join the words back together
  return words?.join(', ');
}

export const getCurrentQuarter = () => {
  const currentMonth = new Date().getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  return Math.ceil(currentMonth / 3);
}

const calculatePercentage = (data) => {
  let newData = [];
  let totalRestaurants = {};

  // Calculate total restaurants for each quarter
  data.forEach(entry => {
    let key = entry.year + "-" + entry.quarter;
    if (!totalRestaurants[key]) {
      totalRestaurants[key] = 0;
    }
    totalRestaurants[key] += entry.restaurants_count;
  });

  // Calculate percentages and store in newData
  data.forEach(entry => {
    let key = entry.year + "-" + entry.quarter;
    // let percentage = (entry.restaurants_count / totalRestaurants[key] * 100).toFixed(2);
    let percentage = entry.restaurants_count;
    newData.push({
      ...entry,
      restaurants_count: percentage
    });
  });

  return newData;
}

export const reorganisedData = (data, type = 'cuisine') => {
  const reorganizedData = {};
  data = calculatePercentage(data);
  // Iterate through the data array
  data.forEach(entry => {
    const key = type === 'cuisine' ? entry.cuisine : entry.dish;
    const year = entry.year;
    const quarter = entry.quarter;

    // Check if the cuisine key exists in the reorganizedData, if not, initialize it
    if (!reorganizedData[key]) {
      reorganizedData[key] = {
        year: year,
        restaurants_count: {} // Initialize an object to store restaurant counts for each quarter
      };
    }

    // Check if the year exists in the reorganizedData, if not, initialize it
    if (!reorganizedData[key].restaurants_count[year]) {
      reorganizedData[key].restaurants_count[year] = {}; // Initialize an object for the year
    }
    const quarterKey = `${quarter}`;
    reorganizedData[key].restaurants_count[year][quarterKey] = (reorganizedData[key].restaurants_count[year][quarterKey] || 0) + entry.restaurants_count;
  });
  // Calculate percentage change among quarters for each cuisine
  Object.keys(reorganizedData).forEach(key => {
    const quarters = reorganizedData[key].restaurants_count;
    Object.keys(quarters).forEach(year => {
      const quartersData = quarters[year];
      const quartersKeys = Object.keys(quartersData);
      quartersKeys.forEach((quarter, index) => {
        if (index > 0) {
          const prevQuarter = quartersKeys[index - 1];
          let currentCount = quartersData[quarter]
          // Convert the string to a float
          currentCount = parseFloat(currentCount);
          let prevCount = quartersData[prevQuarter]
          prevCount = parseFloat(prevCount);
          let percentChange = ((currentCount - prevCount) / prevCount) * 100;
          if (isNaN(percentChange)) {
            percentChange = 0.0
          }
          quartersData[`${index + 1}_percent_change`] = percentChange.toFixed(2);
        }
      });
    });
  });
  return reorganizedData
}

export const sortTrendData = (data, year = null) => {
  // Determine the latest year dynamically if no year is provided
  if (!year) {
    const allYears = Object.values(data).flatMap(item => Object.keys(item.restaurants_count));
    year = Math.max(...allYears.map(Number)); // Get the latest year as a number
  }

  // Convert object to array for sorting
  const dataArray = Object.entries(data);

  // Sort the array based on the first available quarter key under the dynamic year
  dataArray.sort((a, b) => {
    const aKeys = Object.keys(a[1].restaurants_count[year] || {});
    const bKeys = Object.keys(b[1].restaurants_count[year] || {});

    if (aKeys.length === 0 || bKeys.length === 0) return 0; // Skip if no data for the year

    // Find the first quarter key for comparison
    const firstKeyA = aKeys[0];
    const firstKeyB = bKeys[0];

    const aVal = parseFloat(a[1].restaurants_count[year][firstKeyA] || 0);
    const bVal = parseFloat(b[1].restaurants_count[year][firstKeyB] || 0);

    return bVal - aVal; // Sort in descending order based on values
  });

  // Convert the sorted array back to an object
  const sortedData = Object.fromEntries(dataArray);

  return sortedData;
}


export function convertData(data, selectedYearsMap) {
  const newData = {};

  // Initialize the data with restaurants count
  for (const item of data) {
    const dish = item.dish || item.cuisine;
    const year = item.year;
    const quarter = item.quarter;
    const restaurantsCount = item.restaurants_count;

    if (!newData[dish]) {
      newData[dish] = {
        restaurants_count: {}
      };
    }

    if (!newData[dish].restaurants_count[year]) {
      newData[dish].restaurants_count[year] = {};
    }

    newData[dish].restaurants_count[year][quarter] = restaurantsCount;
  }

  // Fill missing quarters with 0 based on selectedYearsMap
  for (const dish in newData) {
    const dishData = newData[dish].restaurants_count;

    for (const year in selectedYearsMap) {
      // Initialize the year if it doesn't exist
      if (!dishData[year]) {
        dishData[year] = {};
      }

      const selectedQuarters = selectedYearsMap[year];
      selectedQuarters.forEach(quarter => {
        // If the quarter is missing, assign it 0
        if (!dishData[year][quarter]) {
          dishData[year][quarter] = 0;
        }
      });
    }
  }

  // Now calculate the percentage change for consecutive quarters
  for (const dish in newData) {
    const years = Object.keys(newData[dish].restaurants_count);

    // Sort the years to handle them in order
    years.sort((a, b) => a - b);

    // Loop through each year
    for (let i = 0; i < years.length; i++) {
      const year = years[i];
      const quarters = Object.keys(newData[dish].restaurants_count[year]);

      // Sort the quarters in numerical order
      quarters.sort((a, b) => a - b);

      // Loop through each quarter in the year
      for (let j = 0; j < quarters.length; j++) {
        const currentQuarter = quarters[j];

        // Check if the next year exists and has quarter data
        const nextYear = years[i + 1];
        const nextYearData = nextYear ? newData[dish].restaurants_count[nextYear] : null;

        // Calculate percentage change between consecutive quarters
        if (nextYearData && nextYearData[1]) {
          const currentCount = newData[dish].restaurants_count[year][currentQuarter];
          const nextQuarterCount = nextYearData[1]; // First quarter of the next year

          // Calculate percentage change
          const percentChange = currentCount !== 0 ? (((nextQuarterCount - currentCount) / currentCount) * 100) : 0;

          // Store percentage change in the next year's quarter data
          newData[dish].restaurants_count[nextYear][`2_percent_change`] = percentChange ? percentChange.toFixed(2) : '';
        }
      }
    }
  }

  return newData;
}


export function preProcess(newData, yearSelectedQuarters) {
  const selectedYearsMap = {};

  yearSelectedQuarters.forEach(({ year, quarters }) => {
    selectedYearsMap[year] = quarters;
  });

  const convertedData = convertData(newData, selectedYearsMap);
  Object.keys(convertedData).forEach(dish => {
    const restaurantData = convertedData[dish].restaurants_count;

    Object.keys(restaurantData).forEach(year => {
      const selectedQuarters = selectedYearsMap[year] || [getCurrentQuarter().toString()]; // Get the selected quarters or default

      const existingQuarters = Object.keys(restaurantData[year]);
      selectedQuarters.forEach(quarter => {
        if (!existingQuarters.includes(quarter.toString())) {
          restaurantData[year][quarter] = 0;
        } else {
        }
      });
    });
  });

  return convertedData;

}

export const transformData = (data) => {
  return data.map(item => {
    const { year, quarters } = item;

    // Check if the quarters array contains objects with 'isChecked' property
    if (quarters[0].hasOwnProperty('isChecked')) {
      // Extract only the 'quarter' values where isChecked is true
      const updatedQuarters = quarters.filter(qtr => qtr.isChecked).map(qtr => qtr.quarter);

      // Return the transformed structure
      return updatedQuarters.length ? { year, quarters: updatedQuarters } : null;
    } else {
      // If no 'isChecked' field, just return the quarters as they are
      return quarters.length ? { year, quarters } : null;
    }
  })
    .filter(item => item !== null);
};

export function dataConverted(dishData, typeKey) {
  const outputData = {};

  dishData.forEach(({ restaurants_count, year, quarter, cuisine, dish }) => {
    // Dynamically use cuisine or dish based on typeKey
    const category = typeKey === "cuisine" ? cuisine : dish;

    if (!outputData[category]) outputData[category] = [];

    let yearData = outputData[category].find(y => y[year]);
    if (!yearData) {
      yearData = { [year]: [] };
      outputData[category].push(yearData);
    }

    const yearQuarters = yearData[year];

    // Find the previous value, which may be in the last quarter of the previous year
    let previousValue;
    if (yearQuarters.length > 0) {
      previousValue = yearQuarters[yearQuarters.length - 1].value;
    } else {
      // Check if there is data from the previous year and get the last quarter
      const previousYearData = outputData[category].find(y => y[year - 1]);
      if (previousYearData) {
        const previousYearQuarters = previousYearData[year - 1];
        previousValue = previousYearQuarters[previousYearQuarters.length - 1].value;
      }
    }

    const percentageChange = previousValue
      ? ((restaurants_count - previousValue) / previousValue * 100).toFixed(2)
      : 0;

    const color = percentageChange > 0 ? '#008000' : percentageChange < 0 ? "#FF0000" : "#ffa500";

    yearQuarters.push({
      quarter,
      value: restaurants_count,
      percentage_change: parseFloat(percentageChange),
      color: color,
    });
  });

  // Log the entire object as a pretty-printed JSON string
  return outputData;
}

export function sortDataByValue(strangeData) {
  let outputData = Object.keys(strangeData).sort((a, b) => {
    const getLastEntry = (data) => {
      let latestYear = Math.max(...data.map(entry => +Object.keys(entry)[0]));

      let latestYearData = data.find(entry => entry[latestYear]);
      if (!latestYearData || !latestYearData[latestYear]) return { latestYear, latestQuarter: 0, latestValue: 0 };

      let latestQuarter = Math.max(...latestYearData[latestYear].map(q => q.quarter));
      let latestValue = latestYearData[latestYear].find(q => q.quarter === latestQuarter).value;

      return { latestYear, latestQuarter, latestValue };
    };

    const aData = getLastEntry(strangeData[a]);
    const bData = getLastEntry(strangeData[b]);

    if (aData.latestYear !== bData.latestYear) {
      return bData.latestYear - aData.latestYear;
    } else if (aData.latestQuarter !== bData.latestQuarter) {
      return bData.latestQuarter - aData.latestQuarter;
    } else {
      return bData.latestValue - aData.latestValue;
    }
  }).reduce((acc, key) => {
    acc[key] = strangeData[key];
    return acc;
  }, {});

  return outputData;
}
